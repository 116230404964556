<template>
  <CRow>
    <Modal :modal="modal"></Modal>
    <CCol lg="12">
      <div class="d-flex align-items-center justify-content-end mb-3">
        <a href="javascript:void(0)" @click="$router.go(-1)">
          <CIcon name="cil-arrow-left" class="mr-2" />Voltar
        </a>
      </div>
      <CForm @submit.prevent="send">
        <CCard>
          <CCardHeader
            class="d-flex justify-content-between align-items-center"
          >
            <h4 class="mb-0">
              {{
                $route.params.id ? 'Editar Categoria' : 'Cadastrar Categoria'
              }}
            </h4>
            <div class="d-flex align-items-center">
              <div class="ml-2 d-flex align-items-center">
                <label class="mb-0">
                  <b>Status</b>
                </label>
                <CSwitch
                  type="checkbox"
                  color="success"
                  variant="3d"
                  class="mx-2"
                  v-bind="statusIcon"
                  :checked.sync="form.status"
                />
              </div>
            </div>
          </CCardHeader>
          <CCardBody>
            <CRow>
              <CCol sm="6">
                <CInput
                  id="first"
                  v-model="form.title"
                  label="Título*"
                  placeholder="Título"
                  required
                  :disabled="processing"
                />
              </CCol>
              <CCol sm="6">
                <FriendlyUrl
                  :value="$route.params.id ? form.slug : form.title"
                  :disabled="processing"
                  @change="(value) => $set(form, 'slug', value)"
                />
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
        <CRow v-if="$route.params.id">
          <CCol sm="12">
            <CardLog :log="log" />
          </CCol>
        </CRow>
        <div class="d-flex justify-content-end align-items-center mt-4 mb-4">
          <a
            v-if="$route.params.id"
            href="javascript:void(0)"
            class="mr-3"
            @click="resetForm"
            >Limpar Alterações</a
          >
          <CInputCheckbox
            v-else
            label="Criar outro"
            class="mr-3"
            :disabled="processing"
            @update:checked="setRedirect"
          />
          <CButton color="success" type="submit" :disabled="processing">
            {{ processing ? 'Salvando...' : 'Salvar' }}
          </CButton>
        </div>
      </CForm>
    </CCol>
  </CRow>
</template>

<script>
import Service from '@/services/news-category.service'
import CardLog from '@/components/ui/CardLog'
import moment from 'moment'
import FriendlyUrl from '@/components/ui/FriendlyUrl'
import Modal from '@/components/ui/Modal'
import { cloneDeep } from 'lodash'

export default {
  metaInfo: {
    title: 'Categorias',
    titleTemplate: 'Manager - OW Interactive - %s'
  },

  components: {
    CardLog,
    FriendlyUrl,
    Modal
  },

  data() {
    return {
      itens: {
        title: '',
        slug: ''
      },
      form: {},
      statusIcon: {
        labelOn: '\u2713',
        labelOff: '\u2715'
      },
      log: {},
      isLoading: true,
      processing: false,
      redirect: true,
      modal: {
        show: false,
        title: '',
        message: '',
        color: ''
      }
    }
  },

  async created() {
    this.setItem()
  },

  methods: {
    async setItem() {
      if (this.$route.params.id) {
        const data = await Service.show(this.$route.params.id)

        if (data) {
          this.itens = {
            ...data
          }

          this.log = {
            created_by: data.created ? data.created.name : 'Seed',
            created_at: moment(data.created_at).format('DD/MM/YYYY HH:mm'),
            updated_by: data.updated ? data.updated.name : null,
            updated_at: moment(data.updated_at).format('DD/MM/YYYY HH:mm')
          }
        }
      }

      this.form = cloneDeep(this.itens)
    },

    resetForm() {
      this.setItem()
    },

    setRedirect(event) {
      this.redirect = !event
    },

    async send() {
      this.processing = true

      let response
      let message

      const data = {
        ...this.form,
        id: this.$route.params.id
      }

      if (this.$route.params.id) {
        response = await Service.update(this.$route.params.id, data)
        message = 'Item foi atualizada com sucesso!'
      } else {
        response = await Service.store(data)
        message = 'Item foi cadastrada com sucesso!'
      }

      this.processing = false

      if (response.status === 200) {
        this.modal = {
          show: true,
          title: 'Sucesso',
          message: message,
          color: 'success',
          redirect: this.redirect
        }

        if (this.redirect) {
          this.resetForm()
          setTimeout(() => {
            this.$router.push(
              this.$route.matched[this.$route.matched.length - 2].path
            )
          }, 3000)
        } else {
          this.resetForm()
          setTimeout(() => {
            this.processing = false
            document.getElementById('first').focus()
            this.modal.show = false
          }, 1000)
        }
      } else if (response.status === 400) {
        this.modal = {
          show: true,
          title: 'Erro',
          message: `${
            response.data.length > 0
              ? response.data[0].message
              : 'Preencha todos os campos corretamente!'
          }`,
          color: 'danger'
        }
      } else {
        this.modal = {
          show: true,
          title: 'Erro',
          message: 'Ocorreu um erro interno, por favor tente novamente!',
          color: 'danger'
        }
      }
    }
  }
}
</script>
